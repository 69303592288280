"use strict";

import {findAll, findIn, addClass, removeClass} from "@elements/dom-utils"
import {onFind} from "@elements/init-modules-in-scope";
import {registerPermissionGrantedCallback} from "@elements/cookie-permissions";


export function init() {
    registerPermissionGrantedCallback('externalVideo', function () {
        onFind('.js-external-video', function () {
            findAll('.js-external-video').forEach(videoElement => {
                let overlay = findIn('.js-external-video-overlay', videoElement);
                let overlayParent = overlay.parentElement;

                if(overlay && overlayParent) {
                    addClass('d-none', overlayParent);
                }

                let iframe = findIn('.embed-responsive-item', videoElement);
                removeClass('bg-light-grey', videoElement);

                iframe.src = iframe.dataset.iframeSrc;
            });
        });
    });
}
