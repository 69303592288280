import * as polyfills from "@elements/dom-utils/polyfills/ie";
import {onFind} from "@elements/init-modules-in-scope";

import { init } from "@elements/cookie-permissions/boostrap-4-native";
if (!_config.admin && !_config.editmode) {
    init();
}

import * as fadeIn from './fade-in';
fadeIn.init();

import * as goToLink from '@elements/go-to-link';
goToLink.init();

import * as toc from '@elements/toc';
toc.init();

import * as lazyImg from '@elements/lazy-img';
lazyImg.init({preventNative: true});

import * as affixNav from './affix-nav';
affixNav.init();

import * as simpleNav from './simple-nav';
simpleNav.init();

import * as emailLink from './email-link';
emailLink.init();

import * as smoothScroll from './smooth-scroll';
smoothScroll.init();

import * as video from './video';
video.init();

import * as acceptCookieService from './accept-cookie-service';
acceptCookieService.init();

import * as googleMap from './google-map';
googleMap.init();

import * as externalVideo from './external-video';
externalVideo.init();